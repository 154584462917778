import React from 'react';
import Head from 'next/head';
import Router from 'next-translate/Router';
import styled from '@emotion/styled';
import { Box, Heading } from 'elcano';
import { mapProducts } from '../picasso';
import { useTranslation } from '../hooks';
import { Products, Navbar } from '../components';
import { getProducts } from '../api';

const SectionHeading = styled(Heading)`
  text-align: left;
  margin-top: 3em;
  margin-bottom: 2em;
`;

const Home = ({ products }) => {
  const { t } = useTranslation();
  return (
    <>
      <Head>
        <title>{t('common:app_title')}</title>
      </Head>

      <Navbar />

      <Box m={3} mt={5}>
        <Box
          sx={{
            px: 4,
            py: 6,
            background: 'url(/banner.png) no-repeat center',
            backgroundSize: 'cover',
            borderRadius: 'default',
            color: 'white',
            bg: 'gray',
            height: 300,
          }}
        />

        <SectionHeading>{t('common:all_products')}</SectionHeading>
        <Products
          products={products}
          onClick={(id) => Router.pushI18n(`/editor/${id}`)}
          showPrice
        />
      </Box>
    </>
  );
};

export async function getStaticProps() {
  const teamziProducts = await getProducts();

  const products = mapProducts(teamziProducts);

  return {
    props: {
      products,
    },
    revalidate: 10,
  };
}

export default Home;
